@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}

body {
  scroll-behavior: smooth;
}

.h1 {
  color: white;
}